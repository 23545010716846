import axios from "axios";
import { BASE_URL } from "../config/auth";

export const getApiWithToken = (path) => {
  const token = localStorage.getItem("token");

  return axios.get(BASE_URL, path, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postApiWithToken = (path, data) => {
  const token = localStorage.getItem("token");

  return axios.post(BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postApiWithoutToken = (path, data) => {
  return axios.post(BASE_URL + path, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
