import React, { useState } from 'react';
import { Box, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderLayout from "./HeaderLayout";
import LeftNavigation from "./LeftNavigation";
import MainRouter from "../../MainRouter/MainRouter";
import "./LeftNavigation.css";
import { useLocation } from 'react-router-dom';

const Layout = () => {
  const location = useLocation();
  const showLeftNav = location.pathname !== "/";
  return (
    <Box className="footer-container">
      <Box sx={{ display: "flex" }}>
      {showLeftNav && <LeftNavigation />}
      <Box
        component="main"
        data-testid="main-content"
        className="custom-all-pages page-contentArea"
        sx={{ flexGrow: 1, p: 3 }}
        style={{ padding: "0", backgroundColor: "#fafaff" }}
      >
        <MainRouter />
      </Box>
    </Box>
      <Box className="footer-bar-content1">
        <p>Powered By Compunnel Inc.</p>
      </Box>
    </Box>
  );
};

export default Layout;
