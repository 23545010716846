import React from "react";
import { TablePagination } from "@mui/material";
import Stack from "@mui/material/Stack";

const Pagination = (props: any) => {
  const { count, pageSize, handleNext, page } = props;

  return (
    <Stack spacing={2}>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleNext}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[]}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default Pagination;
