import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MainRouter from "./MainRouter/MainRouter";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@material-ui/core/styles";
import Layout from "./Components/Layout/Layout"
import axios from "axios";
import { getUserToken, handleLogout } from "./config/auth";

function App() {

  const theme = createTheme({
    palette: {
      primary: {
        main: "#4540DB",
      },
      secondary: {
        light: "#0066ff",
        main: "#0044ff",
        contrastText: "#ffcc00",
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none",
          },
        },
      },
    },
  });

  // Define an array of paths where you want to hide the sidebar
  const pathsToHideSidebar = ["/login", "/register"];

  // Check if the current location path is in the array of paths to hide the sidebar
  const shouldHideSidebar = pathsToHideSidebar.includes(
    window.location.pathname
  );

  axios.interceptors.request.use(
    async (req) => {
      if (!req.url.includes("authenticate")) {
        req.headers["Authorization"] = "Bearer " + getUserToken();
        req.headers["AccessTokenId"] = "AccessTokenId";
      }
      return req;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === 401) {
        handleLogout();
      }
      return error;
    }
  );

  return (
      <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="App">
        {/* {!shouldHideSidebar && <div>{<SideBar />}</div>} */}
        {/* <div>{<SideBar />}</div> */}
        <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
        <Layout />
        </ThemeProvider>
        </StylesProvider>
        {/* <MainRouter /> */}
      </div>
    </div>
  );
}

export default App;
