import { legacy_createStore as createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducer from "./Services/Reducers";
import thunk from "redux-thunk";

const middleware = [thunk];
const store = createStore(rootReducer,
    composeWithDevTools(applyMiddleware(...middleware)));

export default store;
