import {
  SET_HEADER_STEP_ONE,
  SET_HEADER_STEP_THREE,
  SET_HEADER_STEP_TWO,
} from "../Actions/actionTypes";

const initialState = {
  stepOneCompleted: false,
  stepTwoCompleted: false,
  stepThreeCompleted: false,
};

/* istanbul ignore next */
const headerReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_HEADER_STEP_ONE:
      return {
        ...state,
        stepOneCompleted: true,
      };
    case SET_HEADER_STEP_TWO:
      return {
        ...state,
        stepTwoCompleted: true,
      };
    case SET_HEADER_STEP_THREE:
      return {
        ...state,
        stepThreeCompleted: true,
      };
    default:
      return state;
  }
};

export default headerReducer;
