import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  return JSON.parse(localStorage.getItem("UserDetails")) === null ? (
    <Navigate to="/" />
  ) : (
    children
  );
};

export default PrivateRoute;
