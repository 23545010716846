import { SET_LOADING } from "../Actions/actionTypes";

const initialState = {
  loader: false,
};

const loaderReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_LOADING:
      return {
        ...state,
        loader: action.payload,
      };
    default:
      return state;
  }
};

export default loaderReducer;
