import React, { useEffect, useState } from "react";
import { FunctionComponent } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HubspotData from "../Components/HubspotData";
import LinkedinSearch from "../Components/LinkedinSearch";
import GenerateMail from "../Components/GenerateMail";
import Login from "../Login/Login";
import Header from "../Common/Header/Header";
import Emailpage from "../Components/Email/Emailpage";
import PrivateRoute from "./PrivateRoute";
import { Navigate } from "react-router-dom";
import AutomatedFollowUp from "../Components/AutomatedFollowUp/AutomatedFollowUp";
import MailReview from "../Components/MailReview/MailReview";
import Dashboard from "../Components/Dashboard/Dashboard";
import { UnsubscribedFollowUp } from "../Components/UnsubscribedFollowUp/UnsubscribedFollowUp";

const MainRouter: FunctionComponent = () => {
  let location = useLocation();
  const [userDetails, setUserDetails]: any = useState({});

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("UserDetails") || "{}"));
  }, [location]);

  return (
    <>
      {userDetails?.token && <Header />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/linkedin-search"
          element={
            <PrivateRoute>
              <LinkedinSearch />
            </PrivateRoute>
          }
        />
        <Route
          path="/hubspot"
          element={
            <PrivateRoute>
              <HubspotData />
            </PrivateRoute>
          }
        />
        <Route
          path="/generate-mail"
          element={
            <PrivateRoute>
              <GenerateMail />
            </PrivateRoute>
          }
        />
        <Route
          path="/automated-followUp"
          element={
            <PrivateRoute>
              <AutomatedFollowUp />
            </PrivateRoute>
          }
        />
        <Route
          path="/unsubscribe-followUp"
          element={
            <PrivateRoute>
              <UnsubscribedFollowUp />
            </PrivateRoute>
          }
        />
        <Route
          path="/mail-review"
          element={
            <PrivateRoute>
              <MailReview />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route
          path="/email-page"
          element={
            <PrivateRoute>
              <Emailpage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </>
  );
};

export default MainRouter;
