import {
  GET_EMAIL_DETAIL_FAILURE,
  GET_EMAIL_DETAIL_START,
  GET_EMAIL_DETAIL_SUCCESS,
} from "../Actions/actionTypes";

const initialState = {
  mailDetail: null,
  loading: false,
  error: null,
};

/* istanbul ignore next */
const emailReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EMAIL_DETAIL_START:
      return {
        ...state,
        loading: true,
      };
    case GET_EMAIL_DETAIL_SUCCESS:
      return {
        ...state,
        mailDetail: action.payload,
        loading: false,
        error: null,
      };
    case GET_EMAIL_DETAIL_FAILURE:
      return {
        ...state,
        mailDetail: null,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default emailReducer;
