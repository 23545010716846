import { UPDATE_SALESFORCE_DATA } from "../Actions/actionTypes";

const initialState = {
  salesforceData: [],
};

const updateSalesForceValue = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_SALESFORCE_DATA:
      return { ...state, salesforceData: action.payload };
    default:
      return state;
  }
};

export default updateSalesForceValue;
