import { SET_GET_PROFILE_DATA } from "../Actions/actionTypes";

const initialState = {
  getProfileData: [],
};

const updateGetProfileData = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_GET_PROFILE_DATA:
      return { ...state, getProfileData: action.payload };
    default:
      return state;
  }
};

export default updateGetProfileData;
