import { FunctionComponent } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Box } from "@mui/material";
import "./Login.css";

import GirlIcon from "../Assets/Icons/girlIcon.svg";
import DeskIcon from "../Assets/Icons/deskIcon.svg";
import MarketMinderLogo from "../Assets/Icons/MarketLogo.svg";
import compunnelLogo from "../Assets/Icons/compunnel-logo.svg";
import pcIcon from "../Assets/Icons/pcIcon.svg";
import { loginApi } from "../API/endpointMethods";
import aiimage  from "../Assets/Icons/aiimage.svg";

const Login: FunctionComponent = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });

  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  if (JSON.parse(localStorage.getItem("UserDetails") || "{}").token) {
    navigate("/dashboard");
    window.location.reload();
  }

  /* istanbul ignore next */
  const proceedLogin = async (e: any) => {
    e.preventDefault();
    let payload = {
      "Username/Email": formData?.username,
      password: formData?.password,
    };

    try {
      if (validate()) {
        const res: any = await loginApi(payload);
        if (res?.status === 200) {
          let userDetails = {
            token: res?.data?.token,
            email: formData?.username,
          };
          localStorage.setItem("UserDetails", JSON.stringify(userDetails));
          setTimeout(() => {
            navigate("/dashboard");
            window.location.reload();
          },100);
        }
      }
    } catch (error) {}
  };

  const validate = () => {
    let result = true;
    const errors = { username: "", password: "" };

    if (!username) {
      result = false;
      errors.username = "Please Enter Username";
    }

    if (!password) {
      result = false;
      errors.password = "Please Enter Password";
    }

    setFormErrors(errors);

    return result;
  };

  const handleUsernameChange = (e: any) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
    setUsername(e.target.value);
    if (formErrors.username) {
      setFormErrors({ ...formErrors, username: "" });
    }
  };

  const handlePasswordChange = (e: any) => {
    setFormData((v) => ({ ...v, [e.target.name]: e.target.value }));
    setPassword(e.target.value);
    if (formErrors.password) {
      setFormErrors({ ...formErrors, password: "" });
    }
  };

  return (
    <Box className="log-in">
      <Box className="log-in-child" />
      <Box className="frame-parent">
        <Box className="welcome-to-market-minder-parent">
          <Box className="welcome-to-market-container">
            <span>{`Welcome to `}</span>
            <b>Market Minder AI - The Sales Outreach Tool</b>
          </Box>
          <Box className="get-started-parent">
            <b className="get-started">Get Started</b>
            <Box className="log-in1">Log In</Box>
          </Box>
        </Box>
        <form className="frame-wrapper-login">
          <Box className="frame-group-login">
            <Box className="frame-container-login">
              <Box className="frame-Box">
                <Box className="frame-container-login">
                  <Box className="rectangle-parent-login">
                    <TextField
                      className="group-child-login"
                      color="primary"
                      variant="outlined"
                      label="Username / Email"
                      placeholder="Username / Email"
                      margin="normal"
                      required
                      fullWidth
                      id="userName"
                      name="username"
                      autoComplete="userName"
                      onChange={handleUsernameChange}
                      value={formData?.username}
                      autoFocus
                      error={!!formErrors.username}
                      helperText={formErrors.username}
                    />
                  </Box>
                  <Box className="rectangle-parent-login">
                    <TextField
                      className="group-child-login"
                      color="primary"
                      sx={{ width: 488 }}
                      variant="outlined"
                      label="Password"
                      placeholder="Password"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      type="password"
                      id="password"
                      onChange={handlePasswordChange}
                      value={formData?.password}
                      autoComplete="current-password"
                      error={!!formErrors.password}
                      helperText={formErrors.password}
                    />
                    <Box className="password-parent"></Box>
                  </Box>
                </Box>
              </Box>
              <Button
                variant="contained"
                className="send-btn"
                onClick={proceedLogin}
              >
                Login
              </Button>

              <Box className="by-clicking-sign-container">
                <p className="by-clicking-sign-up-you-agree">
                  <span>
                    <span>{`By clicking Sign Up, you agree to `}</span>
                    <span className="stafflinepro">{`Compunnel `}</span>
                  </span>
                  <span>
                    <span className="terms-of-services">Terms of Services</span>
                  </span>
                </p>
                <p className="and-privacy-policy">
                  <span>
                    <span className="and">{`and `}</span>
                    <span className="privacy-policy">Privacy Policy.</span>
                  </span>
                </p>
              </Box>
            </Box>
            <Box className="rectangle-wrapper">
              <Box className="rectangle-Box" />
            </Box>
          </Box>
        </form>
      </Box>
      <Box className="frame-parent1">
        <Box className="frame-container-login">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            className="logoContainer"
          >
            <img src={MarketMinderLogo} alt="" />
            <img src={compunnelLogo} alt="" />
          </Box>
          <Box className="get-started">
            <b>{`Market `}</b>
            <span className="minder">Minder AI</span>
          </Box>
          <Box className="frame-parent2-login">
            <p className="lorem-ipsum-dolor-login-sit-amet-co-wrapper">
              <Box className="lorem-ipsum-dolor-login-container">
                <ul className="lorem-ipsum-dolor-login"></ul>
              </Box>
            </p>
            <Box className="sed-convallis-congue-tristique-wrapper">
              <Box className="nam-in-bibendum-lacus-class-a-wrapper">
                <Box className="lorem-ipsum-dolor-login-container">
                  <ul className="lorem-ipsum-dolor-login"></ul>
                </Box>
              </Box>
            </Box>
            <Box className="sed-convallis-congue-tristique-wrapper">
              <Box className="lorem-ipsum-dolor-login-container">
                <ul className="lorem-ipsum-dolor-login"></ul>
              </Box>
            </Box>
            <Box className="nam-in-bibendum-lacus-class-a-wrapper">
              <Box className="lorem-ipsum-dolor-login-container">
                <ul className="lorem-ipsum-dolor-login"></ul>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="group-group">
          <img  className= "loginImage" alt="" src={aiimage}/>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
