import { useState, useEffect } from 'react';
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { Card, CardContent } from "@mui/material";
import Box from "@mui/material/Box";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

interface Campaign {
  campaign: string;
  count: number;
  id: number;
}

const BorderLinearProgressLight = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: "#9390FA",
    transition: "width 0.3s ease-in-out",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  boxShadow: "none",
}));

const TopProfiles: React.FC = () => {
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);

  const generateRandomId = () => {
    return Math.floor(Math.random() * 1000000);
  }


  useEffect(() => {
    fetch("https://mm-dev-tracking.azurewebsites.net/tracker/campaignreport")?.then(response => response.json())
      ?.then((data: Campaign[]) => {
        const campaignWithIds = data.map((campaign) => ({
          ...campaign,
          id: generateRandomId()
        }))
        setCampaigns(campaignWithIds);
      })
      .catch(error => console.error('Error fetching data:', error));
  }, []);

  if (!campaigns || campaigns.length === 0) {
    return <div>Loading...</div>;
  }

  const totalCounts: number = campaigns.reduce((total, campaign) => total + campaign.count, 0);

  return (
    <Card sx={{ height: "100%", overflowY: "auto" }} className="card-custom-shadow">
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: "14px", fontWeight: "600" }}
          >
            Campaign Progress
          </Typography>
          {campaigns.map((campaign) => (
            <Box
              key={campaign.id}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Item>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  component="div"
                  sx={{ fontWeight: "600" }}
                >
                  <Brightness1Icon
                    sx={{ fontSize: "10px", color: "#9390FA", marginRight: "5px" }}
                  />
                  {campaign.campaign}
                </Typography>
              </Item>
              <BorderLinearProgressLight
                variant="determinate"
                value={(campaign.count / totalCounts) * 100}
              />
              <Item sx={{ textAlign: "right" }}>
                <Typography
                  color="textPrimary"
                  variant="body2"
                  component="div"
                  sx={{ fontWeight: "600" }}
                >
                  {campaign.count} / {totalCounts}
                </Typography>
              </Item>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  );
}

export default TopProfiles;
