const initialState = {
  selectedPersons: [],
};

/* istanbul ignore next */
const selectedPersonData = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SELECTED_PERSONS":
      return {
        ...state,
        selectedPersons: action.payload,
      };
    case "SET_SELECTED_VID":
      return {
        ...state,
        selectedVid: action.payload,
      };
    default:
      return state;
  }
};

export default selectedPersonData;
