import axios from "axios";

export const BASE_URL  = process.env.REACT_APP_BASE_API;

export const REPORT_URL = process.env.REACT_APP_TRACKER_API_REPORT_URL;

export const REPORT_BASE_URL = process.env.REACT_APP_TRACKER_API_BASE_URL

export const TRACKER_API_ENDPOINTS = {
  EVENT_SCORE_BY_EMAIL: `${REPORT_BASE_URL}/tracker/eventScoreByEmail`,
  EVENT_COUNT: `${REPORT_BASE_URL}/tracker/eventcount`,
  EVENT_REPORT: `${REPORT_BASE_URL}/tracker/eventreport`,
  INFRA_COST: `${REPORT_BASE_URL}/tracker/infraCost`,
};

export const getService = (url: string, data: any) => {
  return axios.get(BASE_URL  + url, data);
};

export const postService = (url: string, data: any) => {
  return axios.post(BASE_URL  + url, data);
};

export const putService = (url: string, data: any) => {
  return axios.put(BASE_URL  + url, data);
};

export const deleteService = (url: string, data: any) => {
  return axios.delete(BASE_URL  + url, data);
};

// With auth...
export const postApiWithToken = (path: any, data: any) => {
  const token = localStorage.getItem("token");

  return axios.post(BASE_URL  + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserToken = () => {
  let userDetails: any = JSON.parse(
    localStorage.getItem("UserDetails") || "{}"
  );
  if (userDetails?.token) {
    return userDetails?.token;
  }
};

export const handleLogout = () => {
  localStorage.clear();
  window.location.href = "/";
};
