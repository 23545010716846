const initialState = {
  selectedPersonData: [],
  salesForceList: [],
};

/* istanbul ignore next */
const profileData = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_SELECTED_PERSON_DATA":
      return {
        ...state,
        selectedPersonData: action.payload,
      };
    case "SET_HUBSPOT_LIST_DATA":
      return {
        ...state,
        salesForceList: action.payload,
      };
    default:
      return state;
  }
};

export default profileData;
