import { UPDATE_SELECTED_RADIO_VALUE } from "../Actions/actionTypes";

const initialState = {
  selectedRadioValue: "hubspot",
};

const radioButtonValueReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_SELECTED_RADIO_VALUE:
      return { ...state, selectedRadioValue: action.payload };
    default:
      return state;
  }
};

export default radioButtonValueReducer;
